<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="fa fa-quote-left mr-1"></i>
                                    Emoticons / List / {{$route.params.id ? 'Edit' : 'Add'}}
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/emoticon">
                                        <i class="feather icon-arrow-left"></i> 
                                        Back
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Add'}} Emoticon</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver class="card-content" ref="observer">
                            <form @submit.prevent="save()">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Name</label>
                                                    <input type="text" v-model="model.name" class="form-control"
                                                        placeholder="Input Name">
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                            <div class="form-group">
                                                <label for="">Image (Format file .jpg, .jpeg, .png)</label>
                                                <ValidationProvider name="image" ref="provider" rules="ext:jpg,jpeg,png" v-slot="{ validate, errors }">
                                                    <div class="profile-avatar">
                                                        <img :src="temp_photo ? temp_photo : 'https://via.placeholder.com/150?text=no-image'" class="img-fluid"
                                                            alt="">
                                                        <div class="edit-avatar">
                                                            <label for="file-upload" class="btn-edit">
                                                                <i class="fa fa-pencil"></i>
                                                            </label>
                                                            <input id="file-upload" class="input-file-avatar" type="file" v-on:change="Addphoto">
                                                        </div>
                                                    </div>
                                                    <br><small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="isLoading">
                                    <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Save</div>
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {            
            model: {
                name: null,
                image: null
            },
            temp_photo: null,
            isLoading: false
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/emoticon?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    _.temp_photo = _.model.image.url
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                if (_.model.image == null){
                    _.$toast.warning('Image is required')
                    return;
                }

                _.FData =  new FormData()
                for (let i in _.model){
                    _.FData.append(i, _.model[i])
                }
                _.isLoading = true
                _.axios.post('emoticon' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.FData)
                    .then(resp => {
                        _.$toast.success(resp.data.message)
                        _.$router.push('/emoticon')
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                        _.isLoading = false
                    })
            }
        },
        async Addphoto (ev) {
            const { valid } = await this.$refs.provider.validate(ev)
            if (valid) {     
                const file          = ev.target.files[0]
                this.temp_photo     = URL.createObjectURL(file)
                this.model.image    = file
            } else {
                this.temp_photo     = null
                this.model.image    = null
            }
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
    }
}
</script>