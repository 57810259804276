<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="fa fa-map-pin mr-1"></i>
                                    Place / Category / {{$route.params.id ? 'Edit' : 'Add'}}
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/place/category">
                                        <i class="feather icon-arrow-left"></i> 
                                        Back
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Add'}} Place Category</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver class="card-content" ref="observer">
                            <form @submit.prevent="save()">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Name</label>
                                                    <input type="text" v-model="model.name" class="form-control"
                                                        placeholder="Input Name">
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="isLoading">
                                    <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Save</div>
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {            
            model: {
                name: null
            },
            isLoading: false
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/place/category?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                if (_.$route.params.id){
                    _.axios.patch('place/category/' + _.$route.params.id, _.model)
                        .then(resp => {
                            _.$toast.success(resp.data.message)
                            _.$router.push('/place/category')
                            _.isLoading = false
                        })
                        .catch(err => {
                            _.$toast.warning(err.response.data.message)
                            _.isLoading = false
                        })
                } else {
                    _.axios.post('place/category', _.model)
                        .then(resp => {
                            _.$toast.success(resp.data.message)
                            _.$router.push('/place/category')
                            _.isLoading = false
                        })
                        .catch(err => {
                            _.$toast.warning(err.response.data.message)
                            _.isLoading = false
                        })
                }                
            }
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
    }
}
</script>