<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="fa fa-user-secret"></i>
                                    Admins / {{ $route.params.id ? 'Edit' : 'Add'}} Admin
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/admin">
                                        <i class="feather icon-arrow-left"></i> 
                                        Back
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{ $route.params.id ? 'Edit' : 'Add'}} Admin</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver class="card-content" ref="observer">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Name</label>
                                                            <input type="text" v-model="model.name" id="name" class="form-control"
                                                                placeholder="Input name">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="role" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Role</label>
                                                            <select class="form-control col-6" v-model="model.id_role">
                                                                <option :value="model.id_role == null ? null : ''">Choose Role</option>
                                                                <option v-for="(data, index) in role" :key="index" :value="data.id">{{data.name}}</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="email" v-slot="{ errors }" rules="required|email">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Email</label>
                                                            <input type="text" v-model="model.email" id="email" class="form-control"
                                                                placeholder="Input email">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="password" v-slot="{ errors }" rules="required|min:6" v-if="!$route.params.id">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Password</label>
                                                            <input type="password" v-model="model.password" id="password" class="form-control"
                                                                placeholder="Input password">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="status" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Status</label>
                                                            <select class="form-control col-6" v-model="model.status">
                                                                <option value="">Choose Status</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">Not Active</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="isLoading">
                                    <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Save</div>
                                </button>
                            </form>
                        </ValidationObserver>
                        <button class="btn btn-danger float-right mr-1" v-can="'admin/delete'" v-if="$route.params.id" @click="destroy()">
                            Delete
                        </button>
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {            
            model: {
                id_role: '',
                status: ''
            },
            role: {},
            isLoading: false
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/account/admin?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_role() {
            let _ = this
            _.axios.get('/role')
                .then(resp=>{
                    _.role = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                if (!_.$route.params.id){
                    _.isLoading = true
                    _.axios.post('/account/admin', _.model)
                        .then(resp => {
                            _.$router.push('/admin')
                            _.$toast.success(resp.data.message)
                            _.isLoading = false
                        })
                        .catch(err => {
                            _.$toast.warning(err.response.data.message)
                            _.isLoading = false
                        })
                } else {
                    _.isLoading = true
                    _.axios.patch('/account/admin', _.model)
                        .then(resp => {
                            _.$router.push('/admin')
                            _.$toast.success(resp.data.message)
                            _.isLoading = false
                        })
                        .catch(err => {
                            _.$toast.warning(err.response.data.message)
                            _.isLoading = false
                        })
                }
            }
        },
        destroy() {
            let _ = this
            _.$swal.fire({
                title: 'Delete Admin?',                
                html:   '<p class="popup-title">'+ _.model.name + '</p>' +
                        '<p class="popup-text-normal">Admin that have been deleted cannot be returned.</p>',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Delete',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/account/admin/' + _.$route.params.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.$router.push('/admin')
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    mounted() {
        let _ = this
        _.get_role()
        if (_.$route.params.id) {
            _.get()
        }
    }
}
</script>