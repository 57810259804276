<template>	
    <aside>
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="fa fa-user-md mr-1"></i> 
                                    Doctors / List
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">Detail</h2>
            </div>
        </div>
        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist" style="border: 0px">
                                        <li class="nav-item" v-can="'doctor/info'">
                                            <a class="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true" @click="changeTab('info')">Info</a>
                                        </li>
                                        <li class="nav-item" v-if="model.status == 1" v-can="'doctor/consultation'">
                                            <a class="nav-link" id="consultation-tab" data-toggle="tab" href="#consultation" role="tab" aria-controls="consultation" aria-selected="false" @click="changeTab('consultation')">Consultation History</a>
                                        </li>
                                        <li class="nav-item" v-if="model.status == 1" v-can="'doctor/earning'">
                                            <a class="nav-link" id="earning-tab" data-toggle="tab" href="#earning" role="tab" aria-controls="earning" aria-selected="false" @click="changeTab('earning')">Earning</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                                            <div class="d-flex flex-wrap mt-2">                                        
                                                <div class="col-md-12 col-lg-2">
                                                    <div style="text-align: -webkit-center;">
                                                        <div class="profile-avatar">
                                                            <img :src="temp_photo ? temp_photo:'/assets/img/icon/profile.png'" class="img-fluid" alt="">
                                                        </div>                                                  
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-lg-5">
                                                    <p class="mb-0">Full Name</p>
                                                    <p class="f-16"><b>{{model.name}}</b></p>

                                                    <p class="mb-0">Email</p>
                                                    <p class="f-16"><b>{{model.email}}</b></p>

                                                    <p class="mb-0">Birth Date</p>
                                                    <p class="f-16"><b>{{model.user.birth_date | moment('LL')}}</b></p>

                                                    <p class="mb-0">Profession</p>
                                                    <p class="f-16"><b>{{model.profession}}</b></p>

                                                    <p class="mb-0">Work Experience</p>
                                                    <p class="f-16"><b>{{convert_work_experience}}</b></p>

                                                    <p class="mb-0">Availabilities</p>
                                                    <ul>
                                                        <li v-for="(data, idx) in model.availabilities" :key="idx" class="f-16">
                                                            <b>{{data.day | moment('dddd') }}, {{data.start_time}} - {{data.end_time}}</b>
                                                        </li>
                                                    </ul>

                                                    <p class="mb-0">Time / Session</p>
                                                    <p class="f-16"><b>{{model.session}} minutes</b></p>

                                                    <p class="mb-0">Price / Session</p>
                                                    <p class="f-16"><b>{{model.price | currency}}</b></p>

                                                    <p class="mb-0">Submission Status</p>
                                                    <b>
                                                        <p class="f-16" :class="model.status == 2 ? 'mb-0' : ''" v-html="model.col_status"></p>
                                                        <p class="text-justify" v-if="model.status == 2">Reason: <i>{{model.reason}}</i></p>
                                                    </b>

                                                    <p class="mb-0">Verified Status</p>
                                                    <p class="f-16" :style="model.col_is_verified ? 'color: #3BCC7C' : 'color: #DA636E'"><b>{{model.col_is_verified ? 'Verified' : 'Not Verified'}}</b></p>
                                                </div>
                                                <div class="col-md-12 col-lg-5">
                                                    <p class="mb-0">KTP</p>
                                                    <img :src="model.dok_ktp.url" alt="" class="img-fluid mb-1 btn-transparent rounded" @click="image_detail(model.dok_ktp.url, 'KTP')">
                                                    <p class="mb-0">Ceritificate</p>
                                                    <img :src="model.dok_sertifikat.url" alt="" class="img-fluid mb-1 btn-transparent rounded" @click="image_detail(model.dok_sertifikat.url, 'Ceritificate')">
                                                </div>
                                            </div> 
                                            <div class="text-right" v-if="model.status == 0">
                                                <hr>
                                                <button type="button" class="btn btn-danger mr-1" @click="is_rejected(model)" v-can="'doctor/update'">
                                                    <i class="fa fa-times"></i> Reject
                                                </button>
                                                <button type="button" class="btn btn-success" @click="is_approved(model)" v-can="'doctor/update'">
                                                    <i class="fa fa-check"></i> Approve
                                                </button>                                                
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="consultation" role="tabpanel" aria-labelledby="consultation-tab">
                                            Consultation History Tab
                                        </div>
                                        <div class="tab-pane fade" id="earning" role="tabpanel" aria-labelledby="earning-tab">
                                            Earning Tab
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>        
    </aside>	
</template>

<script>
export default {
	data() {
		return {
            tab: 'info',
            model: {
                user: {
                    photo: {}
                },
                dok_ktp: {},
                dok_sertifikat: {}
            },
            convert_work_experience: '',
            temp_photo: null
		}
    },
    mounted() {
        let _ = this
        _.get()
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/account/doctor?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    if (_.model.user.photo != null){
                        _.temp_photo = _.model.user.photo.url
                    }
                    _.convert_work_experience = _.$moment(_.model.work_experience).format('LL') + ' (' + _.$moment().diff(_.model.work_experience, 'years') + ' years)';
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },        
        changeTab(tab){
            let _ = this
            _.tab = tab
            if (_.tab == 'info'){
                //
            } else if (_.tab == 'consultation') {
                //
            } else if (_.tab == 'earning') {
                //
            }
        },
        is_approved(x) {
            let _ = this
            _.$swal.fire({
                title: 'Approve Submission?',                
                html: '<p class="popup-text-normal">Submission that have been approved cannot be returned.</p>',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#883C9B',
                confirmButtonText: 'Approve',
                }).then((result) => {
                    if (result.value) {
                        _.axios.patch('/account/doctor/update_status/' + x.id, { status : 1 })
                            .then(resp => {
                                if (resp.status) {
                                    _.get()
                                    _.$swal.fire({
                                        title: 'Approved!',
                                        text: 'Verification code ('+x.verification_code+') has been sent to email.',
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        is_rejected(x) {
            let _ = this
            _.$swal.fire({
                title: 'Reject Submission?',                
                html: '<p class="popup-text-normal">Submission that have been rejected cannot be returned.</p>',
                input: 'textarea',
                icon: 'warning',
                inputAttributes: {
                    autocapitalize: 'off',
                    placeholder: 'Input Reason'
                },
                showLoaderOnConfirm: true,
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Reject',
                preConfirm: (reason) => {
                    if (!reason){
                        return _.$swal.showValidationMessage('This reason field is required')
                    }
                    _.axios.patch('/account/doctor/update_status/' + x.id, { status: 2, reason: reason } )
                        .then(resp => {
                            if (resp.status) {
                                _.get()
                                _.$swal.fire({
                                    title: 'Rejected!',
                                    text: 'Submission status has been sent to email.',
                                    icon: 'success',
                                    confirmButtonColor: '#37BA71',
                                    confirmButtonText: 'OK'
                                })     
                            }
                        })
                        .catch(err=>{
                            console.log(err.response)
                        })
                }
            })
        },
        image_detail(x, type) {
            let _ = this
            _.$swal.fire({
                title: type,
                imageUrl: x,
                imageAlt: type,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        }
    }
}
</script>