<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="fa fa-quote-left mr-1"></i>
                                    Wise Sentences / List / {{$route.params.id ? 'Edit' : 'Add'}}
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/wise">
                                        <i class="feather icon-arrow-left"></i> 
                                        Back
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Add'}} Wise Sentence</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver class="card-content" ref="observer">
                            <form @submit.prevent="save()">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <ValidationProvider name="sentence" v-slot="{ errors }" :rules="'required|max:' + max_char">
                                                <div class="form-group " :class="{ 'validation-warning': errors[0] || isError }">
                                                    <label for="">Sentence</label>
                                                    <textarea cols="30" rows="5" class="form-control max" v-model="model.name" placeholder="Input Sentence" @keydown="checkError()"></textarea>
                                                    <small class="text-muted" v-if="errors[0] || isError">{{ isError ? 'The maximum of this sentence field is 300 characters' : errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                            {{ characters_length }} / {{ max_char }} Characters
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="isLoading">
                                    <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Save</div>
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
import $ from 'jquery'
export default {
    data() {
        return {            
            model: {
                name: null
            },
            isLoading: false,
            isError: false,
            max_char: 300
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/wise?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                if (_.$route.params.id){
                    _.isLoading = true
                    _.axios.patch('wise/' + _.$route.params.id, _.model)
                    .then(resp => {
                        _.$toast.success(resp.data.message)
                        _.$router.push('/wise')
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                        _.isLoading = false
                    })
                } else {
                    _.isLoading = true
                    _.axios.post('wise', _.model)
                        .then(resp => {
                            _.$toast.success(resp.data.message)
                            _.$router.push('/wise')
                            _.isLoading = false
                        })
                        .catch(err => {
                            _.$toast.warning(err.response.data.message)
                            _.isLoading = false
                        })
                }
            }
        },
        checkError(){
            let _ = this
            if (_.model.name.length <= _.max_char) {
                _.isError = false
            }
        }
    },
    computed: {
        characters_length: function(){
            return this.model.name ? this.model.name.length : 0;
        } 
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }

        $(document).ready(function($) {
            $('textarea.max').keypress(function(e) {
                if (e.which < 0x20) {
                    return;
                } 
                if (this.value.length == _.max_char) {
                    _.isError = true
                    e.preventDefault()
                } else if (this.value.length > _.max_char) {
                    this.value = this.value.substring(0, _.max_char-1);
                    _.isError = false
                }
            });
        });
    }
}
</script>