<template>
    <div class="auth-page">
        <div class="full-img">
            <img src="/assets/img/gistet-tambun.jpg" class="img-fluid" alt="">
        </div>
        <ValidationObserver ref="observer">
            <div class="login-form" v-if="state != 1">
                <div class="form-logo">
                    <img src="/assets/img/logo-full.png" class="img-fluid" alt="">
                </div>
                <p>Please enter your new password below.</p>
                <form @submit.prevent="reset">
                    <p class="mb-0">New Password</p>
                    <ValidationProvider name="password" rules="required|min:6" v-slot="{ errors }">
                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">                            
                            <input :type="showPassword_1 ? 'text' : 'password'" v-model="password" class="form-control py-2 border-right-0 form-custom-bg" placeholder="New password" ref="password">
                            <span class="input-group-append">
                                <div class="input-group-text form-custom-bg">
                                    <a href="javascript:;" @click="showPassword_1 = !showPassword_1" class="text-blue">
                                        <i :class="showPassword_1 ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                    </a>
                                </div>
                            </span> 
                        </div>
                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                    <p class="mb-0">Confirm New Password</p>
                    <ValidationProvider name="Confirm password" rules="required|confirmed:password" v-slot="{ errors }">
                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">                            
                            <input :type="showPassword_2 ? 'text' : 'password'" v-model="password_2" class="form-control py-2 border-right-0 form-custom-bg" placeholder="Confirm new password" data-vv-as="password">
                            <span class="input-group-append">
                                <div class="input-group-text form-custom-bg">
                                    <a href="javascript:;" @click="showPassword_2 = !showPassword_2" class="text-blue">
                                        <i :class="showPassword_2 ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                    </a>
                                </div>
                            </span> 
                        </div>
                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                    <p><small>Hint: The password should be at least 6 characters long. To make it stronger, use upper and lower case letters, numbers, and symbol like ! " ? $ % ^ )</small></p>
                    <button type="submit" class="btn btn-success waves-effect waves-light w-100 mt-2">Reset Password</button>
                    <p class="val-error text-center mt-1" v-if="state == 0">Failed to reset email, please connect to our customer service.</p>
                </form>                
            </div>
            <div class="login-form" v-else-if="state == 1">
                <div class="form-logo">
                    <img src="/assets/img/logo-full.png" class="img-fluid" alt="">
                </div>
                <p>Successfully reset your password, please click this button below to go to login page.</p>
                <router-link to="/login">
                    <button class="btn btn-success waves-effect waves-light w-100 mt-2">Login</button>
                </router-link>
            </div>            
        </ValidationObserver>  
    </div> 
</template>

<script>
export default {
	name: 'Login',
    data() {
        return {
            password: '',
            password_2: '',
            showPassword_1: false,
            showPassword_2: false,
            state: -1
        }
    },
	methods: {
		async reset() {
            let _ = this
            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.axios.post('/auth/reset-password', { 
                    email: _.$route.query.email,
                    token: _.$route.query.token, 
                    password: _.password
                }).then(resp=>{
                    if (resp.data.status){
                        _.state = 1
                    } else {
                        _.state = 0
                    }
                })
                .catch(err=>{
                    console.log(err.message)
                })
            }
		}
	}
}
</script>