<template>
    <div class="auth-page">
        <div class="full-img">
            <img src="/assets/img/gistet-tambun.jpg" class="img-fluid" alt="">
        </div>
        <ValidationObserver ref="observer">
            <div class="login-form">
                <router-link to="/login"><i class="fa fa-arrow-left"></i> Back to Login</router-link>
                <div class="form-logo">
                    <img src="/assets/img/logo-full.png" class="img-fluid" alt="">
                </div>
                <p>Enter the email address associated with your account and we'll send you a link to reset your password.</p>
                <form @submit.prevent="submit">
                    Email
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">                                                
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">                            
                            <input type="email" v-model="email" class="form-control form-custom-bg" id="user-email" placeholder="Email" required=""> 
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>                           
                        </div>
                    </ValidationProvider>
                
                    <button type="submit" class="btn btn-success waves-effect waves-light w-100" v-if="loadingState == false">{{ state == 1 ? 'Resend email' : 'Continue' }}</button>
                    <button type="button" class="btn btn-success waves-effect waves-light w-100" :disabled="loadingState" v-else><i class="fa fa-spinner fa-spin"></i> </button>
                    <p class="val-error text-center mt-1" v-if="state == 0">Account with this email address is not found. Please try again.</p>
                    <p class="text-center mt-1" v-else-if="state == 1">We have successfully send you a link to reset your password, please check your email.</p>
                </form>                
            </div>            
        </ValidationObserver>  
    </div> 
</template>

<style scoped>
    .val-warning {
        font-family: "Myriad Pro";
        font-size: 14px;
        font-weight: 300;
        color: #ed1c24 !important;
    }
</style>

<script>
export default {
	name: 'Login',
    data() {
        return {
            email: '',
            state: -1,
            loadingState: false
        }
    },
	methods: {
		async submit() {
            let _ = this
            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.loadingState = true
                _.axios.post('/auth/forgot-password', { 
                    email: _.email
                }).then(resp=>{
                    if (resp.data.status) {
                        _.state = 1
                        _.loadingState = false
                    }
                })
                .catch(err=>{
                    console.log(err.message)
                    _.state = 0
                    _.loadingState = false
                })
            }
		}
	}
}
</script>