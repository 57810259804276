<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="fa fa-map-pin mr-1"></i>
                                    Places / List / {{$route.params.id ? 'Edit' : 'Add'}}
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/place">
                                        <i class="feather icon-arrow-left"></i> 
                                        Back
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Add'}} Place</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver class="card-content" ref="observer">
                            <form @submit.prevent="save()">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label for="">Map</label>
                                                        <div class="row mb-1">
                                                            <div class="col input-group form-search-rounded">
                                                                <input class="form-control border-right-0 border" v-model="queryText" type="search" placeholder="Search Location">                                                                
                                                                <span class="input-group-append">
                                                                    <div class="input-group-text bg-transparent align-self-center">
                                                                        <a @click="findCloseBuyButtonPressed()"><i class="fa fa-search"></i></a>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div style="height: 300px;" ref="map">
                                                            <Gmap-Map 
                                                                style="height: 300px;"
                                                                :options="mapOptions"
                                                                :zoom="map.zoom"
                                                                :center="map.center">
                                                                <Gmap-Marker v-for="(marker, index) in markers"
                                                                    :key="index"
                                                                    :position="marker.position"
                                                                    >
                                                                </Gmap-Marker>
                                                                <Gmap-Marker
                                                                    v-if="this.place"
                                                                    :position="{
                                                                        lat: this.place.geometry.location.lat(),
                                                                        lng: this.place.geometry.location.lng(),
                                                                    }">
                                                                </Gmap-Marker>
                                                            </Gmap-Map>
                                                        </div>
                                                    </div>
                                                    
                                                    <ValidationProvider name="category" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Category</label>                                                         
                                                            <multiselect 
                                                                v-model="selectedCategory" 
                                                                :options="options.categories"  
                                                                placeholder="Choose Category" 
                                                                label="name" 
                                                                track-by="name"
                                                                @input="model.id_place_category = selectedCategory.id">
                                                            </multiselect>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Name</label>
                                                            <input type="text" v-model="model.name" class="form-control"
                                                                placeholder="Input Name">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="phone number" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Phone Number</label>
                                                            <input type="text" v-model="model.no_telp" class="form-control"
                                                                placeholder="Input Phone Number">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="country" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Country</label>
                                                            <multiselect 
                                                                v-model="selectedCountry" 
                                                                :options="options.countries"  
                                                                placeholder="Choose Country" 
                                                                label="name" 
                                                                track-by="name"
                                                                @input="
                                                                    selectedProvince = [], 
                                                                    selectedCity = [],
                                                                    model.id_country = selectedCountry.id
                                                                    model.id_province = null, 
                                                                    model.id_city = null">
                                                            </multiselect>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="province" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Province</label>
                                                            <multiselect 
                                                                v-model="selectedProvince" 
                                                                :options="options.provinces.filter(dt => dt.id_country == model.id_country)"  
                                                                placeholder="Choose Province" 
                                                                label="name" 
                                                                track-by="name"
                                                                @input="
                                                                    selectedCity = [],
                                                                    model.id_province = selectedProvince.id, 
                                                                    model.id_city = null">
                                                            </multiselect>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="city" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">City</label>
                                                            <multiselect 
                                                                v-model="selectedCity" 
                                                                :options="options.cities.filter(dt => dt.id_province == model.id_province)"  
                                                                placeholder="Choose City" 
                                                                label="name" 
                                                                track-by="name"
                                                                @input="model.id_city = selectedCity.id">
                                                            </multiselect>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="address" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Address</label>
                                                            <textarea v-model="model.address" class="form-control"
                                                                placeholder="Input Address"
                                                                rows="5">
                                                            </textarea>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="status" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Status</label>
                                                            <select class="form-control" v-model="model.status">
                                                                <option value="0">Not Active</option>
                                                                <option value="1">Active</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col">
                                                    Photo (Format file .jpg, .jpeg, .png)
                                                    <div class="mb-1">
                                                        <ValidationProvider name="image" ref="provider" rules="ext:jpg,jpeg,png" v-slot="{ validate, errors }" v-if="!isUrl">
                                                            <div v-if="temp_photo != null">
                                                                <img :src="temp_photo" class="img-fluid" alt="">
                                                                <button class="btn btn-danger mt-1 mb-1" @click="temp_photo = null, model.photo = null">Delete Photo</button>
                                                            </div>
                                                            <div v-else 
                                                                style="
                                                                    text-align: center; 
                                                                    background: #E5E7E9;
                                                                    border-radius: 8px;
                                                                    height: 176px;
                                                                ">
                                                                <label for="file-upload" class="btn-edit" style="font-size: 40px; padding-top: 50px;">
                                                                    <i class="fa fa-camera" aria-hidden="true"></i> 
                                                                    <p style="font-size: 14px">Choose Photo</p>
                                                                </label>
                                                                <input id="file-upload" class="input-file-avatar d-none" type="file" v-on:change="Addphoto">
                                                            </div>     
                                                            <br>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                        <div class="" v-else>
                                                            <div v-if="temp_photo != null">
                                                                <img :src="temp_photo" class="img-fluid" alt="">
                                                                <button class="btn btn-danger mt-1 mb-1" @click="temp_photo = null, model.photo = null, isUrl = false">Delete Photo</button>
                                                            </div>     
                                                            <br>
                                                        </div>                                                   
                                                    </div>                                                    
                                                    
                                                    <ValidationProvider name="featured" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Featured</label>
                                                            <div class="container ml-0">
                                                                <div class="row mt-1">                            
                                                                    <input type="radio" value="1" name="paid" v-model="model.is_featured" style="align-self: center">
                                                                    <label for="paid" class="ml-1 mr-1">Yes</label>                            
                                                                    <input type="radio" value="0" name="free" v-model="model.is_featured" style="align-self: center">
                                                                    <label for="free" class="ml-1 mr-1">No</label>
                                                                </div>
                                                            </div>                                               
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <section v-if="model.is_featured == 1">
                                                        <ValidationProvider name="wording button" v-slot="{ errors }" rules="required">
                                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Wording Button</label>
                                                                <input type="text" v-model="model.wording" class="form-control"
                                                                    placeholder="Input Wording">
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                        <ValidationProvider name="URL" v-slot="{ errors }" :rules="{ regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, required: true }">
                                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">URL</label>
                                                                <input type="text" v-model="model.url" class="form-control"
                                                                    placeholder="Input URL">
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                        <ValidationProvider name="start & end date" rules="required" v-slot="{ errors }">
                                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Start & End Date</label>
                                                                <div class="position-relative has-icon-right">
                                                                    <date-picker
                                                                        v-model="date_range"
                                                                        format="DD/MM/YYYY"
                                                                        type="date"
                                                                        :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                        value-type="YYYY-MM-DD"
                                                                        placeholder="Input start & end date"
                                                                        style="width: 100%;"
                                                                        range
                                                                        @input="
                                                                            model.start_date = date_range[0]
                                                                            model.end_date   = date_range[1]">
                                                                    </date-picker>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="isLoading">
                                    <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Save</div>
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'

export default {
    data() {
        return {            
            model: {
                id_place_category: null,
                id_country: null,
                id_province: null,
                id_city: null,
                name: null,
                photo: null,
                is_featured: 0,
                status: 1,
                start_date: null,
                end_date: null,
                address: null,
                lat: null,
                lng: null,
                no_telp: null,
                url: null           
            },
            temp_photo: null,
            isLoading: false,
            place: null,
            places: [],
            markers: [],
            mapOptions : {
                    streetViewControl	: false,
                    fullscreenControl	: false,
                    mapTypeControl : false
            },
            queryText: '',
            map : {
                address	: '',
                place	: '',
                center	: { 
                    lat:-2.2145852388265177, 
                    lng:117.104081069627 
                },
                zoom	: 4
            },
            clickedDetail: '',
            options: {
                categories: [],
                countries: [],
                provinces: [],
                cities: []
            },
            selectedCategory: [],
            selectedCountry: [],
            selectedProvince: [],
            selectedCity: [],
            date_range: null,
            isUrl: false
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/place?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    _.temp_photo = _.model.photo.url

                    if(_.model.lat != null && _.model.lng != null){
                        var latLng = {
                            lat: parseFloat(_.model.lat),
                            lng: parseFloat(_.model.lng)
                        }

                        _.markers.push({
                            position: {
                                lat: parseFloat(_.model.lat),
                                lng: parseFloat(_.model.lng),
                            }
                        })

                        _.map.center 	= latLng
                        _.map.zoom 		= 18
                    }

                    _.selectedCategory  = _.model.category
                    _.selectedCountry   = _.model.country
                    _.selectedProvince  = _.model.province
                    _.selectedCity      = _.model.city

                    if (_.model.start_date != null && _.model.end_date != null) {
                        _.date_range = [_.model.start_date, _.model.end_date]
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getCategory() {
            let _ = this
            _.axios.get('/place/category')
                .then(resp=>{
                    _.options.categories = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getCountry() {
            let _ = this
            _.axios.get('/location/country')
                .then(resp=>{
                    _.options.countries = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getProvince() {
            let _ = this
            _.axios.get('/location/province')
                .then(resp=>{
                    _.options.provinces = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getCity() {
            let _ = this
            _.axios.get('/location/city')
                .then(resp=>{
                    _.options.cities = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                if (_.model.photo == null){
                    _.$toast.warning('Image is required')
                    return;
                }

                _.FData =  new FormData()
                for (let i in _.model){
                    _.FData.append(i, _.model[i])
                }
                _.isLoading = true
                _.axios.post('place' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.FData)
                    .then(resp => {
                        _.$toast.success(resp.data.message)
                        _.$router.push('/place')
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                        _.isLoading = false
                    })
            }
        },
        async Addphoto (ev) {
            const { valid } = await this.$refs.provider.validate(ev)
            if (valid) {     
                const file          = ev.target.files[0]
                this.temp_photo     = URL.createObjectURL(file)
                this.model.photo    = file
            } else {
                this.temp_photo     = null
                this.model.photo    = null
            }
        },
        AddPhotoFromURL (url) {
            let _ = this
            _.model.photo = url; 
            _.temp_photo  = url;

            this.isUrl = true
        },
        findCloseBuyButtonPressed() {
            let _ = this
            let service = new _.google.maps.places.PlacesService(this.$refs['map']);
            service.textSearch({ query: _.queryText }, function(result){
                _.places = result
                _.addLocationsToGoogleMaps()
            });
        },
        addLocationsToGoogleMaps() {
            let _ = this
            _.lat = _.places[0].geometry.location.lat()
            _.lng = _.places[0].geometry.location.lng()  

            var map = new _.google.maps.Map(_.$refs['map'], {
                zoom: 14,
                center: new _.google.maps.LatLng(_.lat, _.lng),
                mapTypeId: _.google.maps.MapTypeId.ROADMAP
            });  

            var infowindow = new _.google.maps.InfoWindow();
            _.place = _.places[0]
            
            _.map.center = {
                lat: _.places[0].geometry.location.lat(),
                lng: _.places[0].geometry.location.lng()
            }
            
            _.places.forEach((place) => {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                let marker = new _.google.maps.Marker({
                    position: new _.google.maps.LatLng(lat, lng),
                    map: map
                });  

                _.google.maps.event.addListener(marker, "click", () => {
                    infowindow.setContent(`
                        <p class="mb-0"><b>${place.name}</b></p>
                        <small>${place.formatted_address}</small>`
                    );
                    infowindow.open(map, marker);  

                    var request = {
                        placeId: place.place_id
                    };  

                    let details = new _.google.maps.places.PlacesService(map);
                    details.getDetails(request, function(d) {
                        _.clickedDetail = d
                        _.model.name = d.name
                        _.model.no_telp = d.international_phone_number
                        _.model.lat = d.geometry.location.lat()
                        _.model.lng = d.geometry.location.lng()
                        _.model.url = d.website
                        _.model.address = d.formatted_address
                                                                        
                        var ixCountry = d.address_components.length - 2;
                        d.address_components.forEach( (a, ix) => {
                            if ( a.types.includes('country') ) {
                                ixCountry = ix
                            }
                        }, ixCountry)

                        let country         = d.address_components[d.address_components.length-(d.address_components.length - ixCountry)].long_name
                        _.selectedCountry   = _.options.countries.filter(x => x.name == country)
                        if (_.selectedCountry.length > 0) {
                            _.model.id_country  = _.selectedCountry[0].id
                        }

                        let administrative_area_level_1 = d.address_components[d.address_components.length-(d.address_components.length - (ixCountry - 1))].long_name
                        _.selectedProvince   = _.options.provinces.filter(x => x.name == administrative_area_level_1)
                        if (_.selectedProvince.length > 0) {
                            _.model.id_province  = _.selectedProvince[0].id
                        }

                        let administrative_area_level_2 = d.address_components[d.address_components.length-(d.address_components.length - (ixCountry - 2))].long_name
                        _.selectedCity   = _.options.cities.filter(x => x.name == administrative_area_level_2)
                        if (_.selectedCity.length > 0) {
                            _.model.id_city  = _.selectedCity[0].id
                        }
                        
                        if (d.photos) {
                            _.AddPhotoFromURL(d.photos[0].getUrl())
                        }
                    });
                });  
                _.markers.push({
                    position: {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    }
                })
            });    
        }
    },
    mounted() {
        let _ = this
        _.getCategory()
        _.getCountry()
        _.getProvince()
        _.getCity()

        if (_.$route.params.id) {
            _.get()
        }
    },
    computed: {
        google: gmapApi
    }
}
</script>