<template>
    <div class="content-body">
        <div class="mb-2">
            <router-link to="/">
                <i class="fa fa-dashboard"></i>
                <span class="menu-title"> Dashboard</span>
            </router-link>
        </div>
        <div class="row">
            <div class="col d-flex align-items-stretch">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                <i class="fa fa-money"></i>
                            </div>
                            <div class="ic-title large">0</div>
                            <p class="line-ellipsis auto-truncate" data-toggle="tooltip" data-placement="bottom" data-original-title="Transactions">Transactions</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col d-flex align-items-stretch">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                <i class="fa fa-users"></i>
                            </div>
                            <div class="ic-title large">{{users}}</div>
                            <p class="line-ellipsis auto-truncate" data-toggle="tooltip" data-placement="bottom" data-original-title="Users">Users</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col d-flex align-items-stretch">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                <i class="fa fa-user-md"></i>
                            </div>
                            <div class="ic-title large">{{doctors}}</div>
                            <p class="line-ellipsis auto-truncate" data-toggle="tooltip" data-placement="bottom" data-original-title="Doctors">Doctors</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col d-flex align-items-stretch">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                <i class="fa fa-user-secret"></i>
                            </div>
                            <div class="ic-title large">{{admins}}</div>
                            <p class="line-ellipsis auto-truncate" data-toggle="tooltip" data-placement="bottom" data-original-title="Admins">Admins</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {
            users: 0,
            admins: 0,
            doctors: 0
        }
    },
    methods: {
        get_user(){
            let _ = this
            _.axios.get('dashboard/user')
            .then(resp=>{
                _.users = resp.data.data ? resp.data.data : 0
            })
            .catch(err=>{
                console.log(err.response)
            })
        },
        get_admin(){
            let _ = this
            _.axios.get('dashboard/admin')
            .then(resp=>{
                _.admins = resp.data.data ? resp.data.data : 0
            })
            .catch(err=>{
                console.log(err.response)
            })
        },
        get_doctor(){
            let _ = this
            _.axios.get('dashboard/doctor')
            .then(resp=>{
                _.doctors = resp.data.data ? resp.data.data : 0
            })
            .catch(err=>{
                console.log(err.response)
            })
        }
    },
    async mounted() {
        let _ = this
        await _.get_user()
        await _.get_admin()
        await _.get_doctor()
    }
}
</script>
