<template>
	<div>
		<div class="content-header" v-if="breadcumb">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item" v-if="breadcumb">                                
                                <a style="display: inline-flex;">
                                    <i class="mr-1 fa" :class="icon" v-if="icon != null"></i>
                                    {{ breadcumb }}
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{ title }} </h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col mb-1">
                                            <slot name="search"></slot>
                                        </div>
                                        <div class="col-4 mb-1">
                                            <div class="btn-responsive float-right">
                                                <slot name="button"></slot>
                                                <slot name="limit2"></slot>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col mb-1">
                                            <div class="btn-responsive">
                                                <slot name="filter"></slot>
                                            </div>
                                        </div>                                        
                                        <div class="col mb-1">
                                            <slot name="limit"></slot>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <slot name="columnHeader"></slot>
                                            <tbody>
                                                <tr v-for="(data, key) in dataTable.lists.data" :key="key">
                                                    <td class="text-center">{{ (dataTable.lists.current_page - 1) * dataTable.lists.per_page + key + 1 }}</td>
                                                    <td v-for="(column, kcolumn) in dataTable.columns" :key="kcolumn">       
                                                        <span v-if="(column == 'created_at' || column == 'updated_at')">
                                                            {{ data[column] | moment("DD/MM/YYYY HH.mm") }} WIB  
                                                        </span>
                                                        <span v-else-if="(column == 'expire_date')">
                                                            {{ data[column] | moment("DD/MM/YYYY") }} 23.59 WIB
                                                        </span>
                                                        <span v-else-if="column == 'image'">
                                                            <img v-if="data[column] != null" :src="data[column]['url']" class="avatar-small"> 
                                                        </span> 
                                                        <span v-else-if="column == 'price'">
                                                            {{ data[column] | currency }}
                                                        </span> 
                                                        <span v-else-if="column == 'status'">
                                                            {{ data[column] == 1 ? 'Active' : 'Not Active' }} 
                                                        </span>
                                                        <span v-else-if="column == 'col_is_verified'" :style="data[column] ? 'color: #3BCC7C' : 'color: #DA636E'">
                                                            {{ data[column] == 1 ? 'Verified' : 'Not Verified' }} 
                                                        </span>
                                                        <span v-else-if="column == 'name'">
                                                            {{ data[column].length > 100 ? data[column].substring(0, 100) + '...' : data[column]}}
                                                        </span>
                                                        <span v-else v-html="data[column] == null ? '-' : data[column]"></span>
                                                    </td>
                                                    <td>
                                                        <slot name="action" v-bind:data="data"></slot>
                                                    </td>
                                                </tr>
                                            </tbody>           
                                        </table>
                                        <div class="col text-center mt-1">
                                            <p v-if="dataTable.lists.total == 0">Data not found.</p>
                                        </div>                                                                           
                                    </div>
                                    <PaginateList :data="dataTable.lists" v-if="dataTable.lists.total != 0"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>

<script>
import PaginateList from '@/components/PaginateList'

export default {
	name: 'List',
    props: ['title', 'breadcumb', 'dataTable',  'isBreadcrumb', 'icon', 'svgIcon'],
    components: {
        PaginateList
    },
    data() {
        return {
            role_id: localStorage.getItem('role_id')
        }
    }
}
</script>