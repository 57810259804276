<template>
    <div class="content-body">
        <div class="mb-2">
            <router-link to="/">
                <i class="fa fa-user mr-1"></i>
                <span class="menu-title">Edit Profile</span>
            </router-link>
        </div>
        <section id="basic-datatable">
            <div class="card">
                <div class="card-content">
                    <div class="card-body text-center">
                        <div class="profile-avatar">
                            <img :src="temp_photo ? temp_photo:'/assets/img/icon/profile.png'" class="img-fluid"
                                alt="">
                            <div class="edit-avatar">
                                <label for="file-upload" class="btn-edit">
                                    <i class="fa fa-pencil"></i>
                                </label>
                                <input id="file-upload" class="input-file-avatar" type="file" v-on:change="Addphoto">
                            </div>
                        </div>
                    </div>

                    <ValidationObserver tag="div" class="card-body" ref="observer">
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <form @submit.prevent="check_email()">
                                    <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label for="">Full Name</label>
                                            <input type="text" v-model="model.name" id="name" class="form-control"
                                                placeholder="Full name">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>

                                    <ValidationProvider name="email" v-slot="{ errors }" rules="required|email">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label for="">Email</label>
                                            <input type="email" v-model="model.email" id="email" class="form-control"
                                                placeholder="Alamat email">
                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                    <button type="submit" class="btn btn-success float-right" :disabled="isLoading">
                                        <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                        <div v-else>Save</div>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
	name: 'EditProfile',
    data() {
        return {
            temp_photo: null,
            model: {
                name: '',
                email: '',
                photo: ''
            },
            isLoading: false,
            isChangeEmail: false
        }
    },
    mounted() {
        let _ = this
        if ( localStorage.getItem('photo') != null || localStorage.getItem('photo') != 'null' || localStorage.getItem('photo') != 'undefined') {
            _.temp_photo = localStorage.getItem('photo')
        }

        _.model = {
            name: localStorage.getItem('name'),
            email: localStorage.getItem('email')
        }
    },
    methods: {
        save() {
            let _ = this
            _.FData =  new FormData()
            for (let i in _.model){
                _.FData.append(i, _.model[i])
            }
            _.isLoading = true
            _.axios.post('account/' + localStorage.getItem('id'), _.FData)
                .then(resp => {
                    _.$toast.success(resp.data.message)
                    if (_.isChangeEmail){
                        _.$store.dispatch('logout')
                        _.$router.push('/login')
                    } else {
                        _.me()
                        _.isLoading = false
                    }
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                    _.isLoading = false
                })
        },
        me() {
            let _ = this
            _.axios.get('auth/me')
                .then(resp => {
                    _.$root.$emit('changeProfile', resp.data.data)
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })
        },
        Addphoto (ev) {             
            const file          = ev.target.files[0];
            this.model.photo    = ev.target.files[0]; 
            this.temp_photo     = URL.createObjectURL(file);     
        },
        async check_email(){
            let _ = this
            if (_.model.email == localStorage.getItem('email')){
                _.isChangeEmail = false
                return _.save()
            }

            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                _.axios.get('/auth/is-available/email?key=' + _.model.email)
                .then(resp=>{
                    if (resp.data.status){
                        _.$swal.fire({
                            title: 'Are you sure you want to change your email?',
                            html: '<p class="popup-text-normal">You will be logged out automatically when you change your email.</p>',
                            showCancelButton: true,
                            cancelButtonText: 'Cancel',
                            confirmButtonColor: '#EF8481',
                            confirmButtonText: 'Continue',
                            }).then((result) => {
                                if (result.value) {
                                    _.isChangeEmail = true
                                    _.save()
                                }
                            })
                    } else {
                        _.$toast.warning('Email already exist.')
                    }
                    _.isLoading = false
                })
            }
        }
    }
}
</script>