<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :icon="table.icon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-6 input-group form-search-rounded">
                        <input class="form-control border-right-0 border" v-model="search" type="search" placeholder="Search Email/Name of Doctor">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search"></i>
                            </div>
                        </span>
                    </div>
                    <div class="col-4 input-group form-search-rounded">
                        <button class="btn btn-primary waves-effect waves-light" title="Filter" data-toggle="modal" data-target="#filter">
                            <i class="fa fa-filter"></i> Filter
                        </button>
                    </div>
                </div>                
			</template>



            <template v-slot:button>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Show
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">All</option>
                        </select>
                    </div>
				</div>
			</template>	

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Submission Status</th>
                        <th>Verification Status</th>
                        <th>Registered Date</th>
                        <th width="10%">Action</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <router-link :to="'/doctor/detail/' + data.id_account">
                        <button type="button" class="btn btn-success waves-effect waves-light" title="Detail">
                            Detail
                        </button>
                    </router-link>
                </div>
            </template>
		</List>
        <div class="modal fade" id="filter">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Filter</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-1" v-for="(f, idx) in filter" :key="idx">
                            <div class="col-8 col-sm-10">
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group mb-0">
                                            <label for="filter_by">Filter By</label>
                                            <select class="form-control" v-model="f.by" :disabled="f.by != '-'">
                                                <option v-for="(data, index) in filterOption" :key="index" :value="data.by">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>                                   

                                    <div class="col-12 col-sm-6" v-if="f.by == 'submission'">
                                        <div class="form-group mb-0">
                                            <label for="filter_by">Submission Status</label>                                                                                        
                                            <select class="form-control" v-model="filterby.submission">
                                                <option v-for="(data, index) in optionsSubmissionStatus" :key="index" :value="data.value">
                                                    {{data.name}}
                                                </option>
                                            </select>                                                                             
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'verification'">
                                        <div class="form-group mb-0">
                                            <label for="filter_by">Verification Status</label>                                                                                        
                                            <select class="form-control" v-model="filterby.verification">
                                                <option v-for="(data, index) in optionsVerificationStatus" :key="index" :value="data.value">
                                                    {{data.name}}
                                                </option>
                                            </select>                                                                             
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else>
                                        <div class="form-group mb-0">
                                            <label for="filter_by">-</label>
                                            <input type="text" class="form-control" disabled>                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 col-sm-2 d-flex align-items-center pt-2">
                                <button class="btn btn-primary btn-rounded" @click="addFilter()" v-if="idx == filter.length - 1">
                                    <i class="fa fa-plus"></i>
                                </button>
                                <button class="btn btn-danger btn-rounded" @click="deleteFilter(idx)" v-if="filter.length > 1">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="reset()" data-dismiss="modal">Reset</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="getList()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
// import $ from 'jquery'
import List from '@/components/List.vue'

export default {
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List",
				breadcumb: "Doctors",
                icon: "fa-user-md",
                dataTable: {
                    lists: [],
                    columns: ['name', 'email', 'col_status', 'col_is_verified', 'created_at']
                }
			},
            search: '',
            limit: 10,
            filterby: {
                submission: '',
                verification: ''
            },
            filter: [
                {
                    'by': '-'
                }
            ],
            filterOption: 
            [
                {
                    'name': '-',
                    'by': '-'
                },
                {
                    'name': 'Submission Status',
                    'by': 'submission'
                },
                {
                    'name': 'Verification Status',
                    'by': 'verification'
                }
            ],
            optionsSubmissionStatus: [
                {
                    name: 'Pending',
                    value: 0,                    
                },
                {
                    name: 'Approved',
                    value: 1,                    
                },
                {
                    name: 'Rejected',
                    value: 2,                    
                }
            ],
            optionsVerificationStatus: [
                {
                    name: 'Verified',
                    value: '1',                    
                },
                {
                    name: 'Not Verified',
                    value: '0',                    
                }
            ],
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/account/doctor?key=' + _.search + '&page=' + page + '&limit=' + _.limit 
                    + '&submission=' + _.filterby.submission
                    + '&verification=' + _.filterby.verification)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        addFilter(){
            let _ = this
            _.filter.push({
                name	: '-',
                by      : '-'
			})
        },
        deleteFilter(x) {
            let _ = this
            switch (_.filter[x].by) {
                case 'submission':
                    _.filterby.submission = ''
                    break;
                case 'verification':
                    _.filterby.verification = ''
                    break;
            
                default:
                    break;
            }            
            _.filter.splice(x, 1);            
        },
        reset() {
            let _ = this 
            
            _.filterby.submission = ''
            _.filterby.verification = ''
            _.filter = [
                {
                    'by': '-'
                }
            ]

            _.getList()
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>