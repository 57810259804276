export default {
	increment(state) {
      	state.count++
    },
    UPDATE_USER(state, payload) {
    	state.user 			= payload.user
		state.token 		= payload.access_token
		state.permissions 	= payload.permissions

    	localStorage.setItem('id', payload.user.id)
    	localStorage.setItem('name', payload.user.name)
		localStorage.setItem('email', payload.user.email)
		localStorage.setItem('token', payload.access_token)
		localStorage.setItem('permissions', payload.permissions)
		if (payload.user.photo != null){
			if (payload.user.photo.url != null){
				localStorage.setItem('photo', payload.user.photo.url)
			}
		} 
    },
    LOGOUT_USER(state) {
    	state.user = null
    	state.token = null    	
    	localStorage.removeItem('id')
		localStorage.removeItem('name')
		localStorage.removeItem('email')
		localStorage.removeItem('token')
		localStorage.removeItem('permissions')
		localStorage.removeItem('photo')
    }
}