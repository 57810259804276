<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="fa fa-music mr-1"></i>
                                    Musics / List / {{$route.params.id ? 'Edit' : 'Add'}}
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/music">
                                        <i class="feather icon-arrow-left"></i> 
                                        Back
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Add'}} Music</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver class="card-content" ref="observer">
                            <form @submit.prevent="save()">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <ValidationProvider name="music category" v-slot="{ errors }" rules="required">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Music Category</label>
                                                    <select v-model="model.id_music_category" class="form-control">
                                                        <option value="">Choose Category</option>
                                                        <option :value="data.id" v-for="(data, index) in option_music_category" :key="index">
                                                            {{ data.name }}
                                                        </option>
                                                    </select>
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                            <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                                <div class="form-group">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Name</label>
                                                        <input type="text" v-model="model.name" class="form-control"
                                                            placeholder="Input Name">
                                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                            <ValidationProvider name="price type" v-slot="{ errors }" rules="required">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Price Type</label>
                                                    <div class="container ml-0">
                                                        <div class="row mt-1">                                                        
                                                            <input type="radio" value="0" name="free" v-model="model.price_type" style="align-self: center" v-on:change="model.price = 0">
                                                            <label for="free" class="ml-1 mr-1">Free</label>
                                                            <input type="radio" value="1" name="paid" v-model="model.price_type" style="align-self: center" v-on:change="model.price = 0">
                                                            <label for="paid" class="ml-1 mr-1">Paid</label>
                                                        </div>
                                                    </div>                                               
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                            <ValidationProvider name="price" v-slot="{ errors }" rules="required|min_value:100" v-if="model.price_type == 1">
                                                <div class="form-group">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Price</label>
                                                        <input type="number" v-model="model.price" class="form-control"
                                                            placeholder="Input Price">
                                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                    </div>
                                                </div>
                                            </ValidationProvider>
                                            <div class="form-group">
                                                <label for="">File (Format file .mp3, maximum 10MB)</label>
                                                <ValidationProvider name="music" ref="provider" rules="ext:mp3" v-slot="{ validate, errors }">
                                                    <div class="custom-file" v-if="temp_file == null">
                                                        <input type="file" class="custom-file-input" id="customFile" v-on:change="Addfile">
                                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                                    </div>
                                                    <br><small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </ValidationProvider>
                                                <audio controls v-if="temp_file != null" class="mt-1">
                                                    <source :src="temp_file" type="audio/mpeg">
                                                    Your browser does not support the audio element.
                                                </audio>
                                                <br>
                                                <a class="link" @click="temp_file = null" v-if="temp_file != null">Change song</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="isLoading">
                                    <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Save</div>
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {            
            model: {
                name: null,
                file: null,
                id_music_category: '',
                price_type: "0",
                price: 0
            },
            temp_file: null,
            option_music_category: [],
            isLoading: false
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/music?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    _.temp_file = _.model.file.url
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_music_category() {
            let _ = this
            _.axios.get('/music/category')
                .then(resp=>{
                    _.option_music_category = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                if (_.model.file == null || _.temp_file == null){
                    _.$toast.warning('Song is required')
                    return;
                }

                _.FData =  new FormData()
                for (let i in _.model){
                    _.FData.append(i, _.model[i])
                }
                _.isLoading = true
                _.axios.post('music' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.FData)
                    .then(resp => {
                        _.$toast.success(resp.data.message)
                        _.$router.push('/music')
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                        _.isLoading = false
                    })
            }
        },
        async Addfile (ev) {             
            const { valid } = await this.$refs.provider.validate(ev)
            if (valid) {
                const file          = ev.target.files[0];
                let max_file_size = 10; //mb
                if (file.size > (1024 * 1024) * max_file_size) {
                    ev.preventDefault();
                    this.$toast.warning('File to big (> '+ max_file_size +'MB)')
                    return;
                }

                this.model.file     = ev.target.files[0]; 
                this.temp_file     = URL.createObjectURL(file);
            } else {
                this.model.file    = null
                this.temp_file     = null
            }
        }
    },
    mounted() {
        let _ = this
        _.get_music_category()
        if (_.$route.params.id) {
            _.get()
        }
    }
}
</script>