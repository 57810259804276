<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :icon="table.icon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-6 input-group form-search-rounded">
                        <input class="form-control border-right-0 border" v-model="search" type="search" placeholder="Search Email/Name of User">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search"></i>
                            </div>
                        </span>
                    </div>
                </div>                
			</template>

            <template v-slot:button>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Show
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">All</option>
                        </select>
                    </div>
				</div>
			</template>	

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Registered Date</th>
                        <th>Phone Number</th>
                        <th width="10%">Action</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <router-link :to="'/user/' + data.id_account">
                        <button type="button" class="btn btn-success waves-effect waves-light">
                            Detail
                        </button>
                    </router-link>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'UserIndex',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List",
				breadcumb: "Users",
                icon: "fa-users",
                dataTable: {
                    lists: [],
                    columns: ['name', 'email', 'created_at', 'phone_number']
                }
			},
            search: '',
            limit: 10,
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/account/user?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>