import axios from "../axios"

export default {
	login({ commit }, payload) {
		commit('UPDATE_USER', payload)
	},
	tryAutoLogin({ commit }) {
		axios.get('auth/permissions')
			.then(resp=>{
				localStorage.setItem('permissions', resp.data.data.toString())
			})

		let updateUser = {
			user: {
				id		: localStorage.getItem('id'),
				name	: localStorage.getItem('name'),
				email	: localStorage.getItem('email'),
				photo	: localStorage.getItem('photo')
			},
			access_token: localStorage.getItem('token'),
			permissions: localStorage.getItem('permissions')						
		}				
		commit('UPDATE_USER', updateUser)
	},
	logout({ commit }) {
		commit('LOGOUT_USER')
	}
}