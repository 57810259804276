<template>
    <div class="auth-page">
        <div class="full-img">
            <img src="/assets/img/gistet-tambun.jpg" class="img-fluid" alt="">
        </div>
        <ValidationObserver ref="observer">
            <div class="login-form">
                <div class="form-logo">
                    <img src="/assets/img/logo-full.png" class="img-fluid" alt="">
                </div>
                <p><b>Welcome to Santooi! 👋</b></p>
                <p>Please sign-in to your account and start the adventure</p>
                <form @submit.prevent="check_email()">
                    Email
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">                                                
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">                            
                            <input type="email" v-model="email" class="form-control form-custom-bg" id="user-email" placeholder="Email"> 
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>                           
                        </div>
                    </ValidationProvider>
                    Password <small class="float-right"><router-link to="/forgot-password" class="text-primary">Forgot Password?</router-link></small>
                    <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">                            
                            <input :type="showPassword ? 'text' : 'password'" v-model="password" class="form-control py-2 border-right-0 form-custom-bg" id="user-password" placeholder="Password">
                            <span class="input-group-append">
                                <div class="input-group-text form-custom-bg">
                                    <a href="javascript:;" @click="showPassword = !showPassword" class="text-blue">
                                        <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                    </a>
                                </div>
                            </span> 
                        </div>
                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                    <button type="submit" class="btn btn-success waves-effect waves-light w-100 mt-2" :disabled="isLoading">
                        <div v-if="isLoading">Signing In <i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>Sign In</div>
                    </button>

                    <p class="val-error text-center mt-1" v-if="email.length > 0 && password.length > 0 && authFalse == 1">
                        The password you entered is incorrect. Please try again.
                    </p>
                    <p class="val-error text-center mt-1" v-if="email.length > 0 && password.length > 0 && authFalse == 2">
                        The email you entered is incorrect. Please try again.
                    </p>
                    <p class="val-error text-center mt-1" v-if="email.length > 0 && password.length > 0 && authFalse == 3">
                        Your Role has been disabled. Please contact your system administrator.
                    </p>
                    <p class="val-error text-center mt-1" v-if="email.length > 0 && password.length > 0 && authFalse == 4">
                        Your Account has been disabled. Please contact your system administrator.
                    </p>
                </form>                
            </div>            
        </ValidationObserver>  
    </div> 
</template>

<style scoped>
    .val-warning {
        font-family: "Myriad Pro";
        font-size: 14px;
        font-weight: 300;
        color: #ed1c24 !important;
    }
</style>

<script>
export default {
	name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            authFalse: 0,
            showPassword: false,
            isLoading: false
        }
    },
	methods: {
		login() {
            let _ = this        
            _.axios.post('/auth/login', { 
                email: _.email, 
                password: _.password
            }).then(resp=>{
                let logged_user = resp.data.data
                _.$store.dispatch('login', logged_user)
                if ( logged_user.user.role == 1){
                    if ( logged_user.access_token ) {
                        if (logged_user.user.roles[0].status){
                            if (logged_user.user.status){
                                if (logged_user.permissions.length > 0) {
                                    let searchPermission = logged_user.permissions.filter(x => x.includes('/read'));
                                    if (searchPermission.length > 0) {
                                        switch (searchPermission[0]) {
                                            case 'dashboard/read':
                                                window.location = '/'
                                                break;
                                            case 'user/read':
                                                window.location = '/user'
                                                break;
                                            case 'admin/read':
                                                window.location = '/admin'
                                                break;
                                            case 'doctor/read':
                                                window.location = '/doctor'
                                                break;
                                            case 'emoticon/read':
                                                window.location = '/emoticon'
                                                break;
                                            case 'sentence/read':
                                                window.location = '/wise'
                                                break;
                                            case 'music/read':
                                                window.location = '/music'
                                                break;
                                            case 'category/read':
                                                window.location = '/place/category'
                                                break;
                                            case 'place/read':
                                                window.location = '/place'
                                                break;
                                            case 'role/read':
                                                window.location = '/role'
                                                break;
                                        
                                            default:
                                                window.location = '/user/edit_profile'
                                                break;
                                        }
                                    } else {
                                        window.location = 'user/edit_profile'
                                    } 
                                } else {
                                    window.location = 'user/edit_profile'
                                }
                            } else {
                                _.authFalse = 4
                            }
                        } else {
                            _.authFalse = 3
                        }
                    } else {
                        _.authFalse = 1
                    }

                    _.isLoading = false
                } else {
                    _.authFalse = 1
                    _.isLoading = false
                }
            })
            .catch(err=>{
                if (err){
                    _.authFalse = 1
                    _.isLoading = false
                }
            })
		},
        async check_email(){
            let _ = this
            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.authFalse = 0
                _.isLoading = true
                _.axios.get('/auth/is-available/email?key=' + _.email)
                .then(resp=>{
                    if (resp.data.status){
                        _.authFalse = 2
                        _.isLoading = false
                    }
                })
                .catch(err=>{
                    if (err){
                        _.login()
                    }
                })
            }
        }
	}
}
</script>