import axios from 'axios'
import router from '../router'
import store from '../store'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
axios.defaults.headers.get['Accept'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';

axios.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 401) {
        store.dispatch('logout')
        router.push('/login')
    }
    return Promise.reject(error)
})
export default axios
