<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="fa fa-cogs mr-1"></i>
                                    Roles & Permissions / Permissions
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link :to="'/role/' + $route.params.id">
                                        <i class="feather icon-arrow-left"></i> 
                                        Back
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">Role's Permissions</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="d-flex flex-wrap">
                                                <div class="col-6 mb-2" v-for="(dt, idx) in title" :key="idx">
                                                    <h5 class="font-bold">{{dt.name}}</h5>
                                                    <div class="row mt-1" v-for="(data, index) in permissions.filter(w => w.name.indexOf(dt.permission) != -1)" :key="index">
                                                        <div class="col-1"></div>
                                                        <div class="col text-right" style="text-transform:capitalize;">{{ generateName(data.name) }}</div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <input class="align-self-center mr-1 ml-1" type="radio" value="1" v-model="model_permissions[data.id-1]">
                                                                <label for="">Yes</label>
                                                                <input class="align-self-center mr-1 ml-1" type="radio" value="0" v-model="model_permissions[data.id-1]">
                                                                <label for="">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="isLoading">
                                    <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Save</div>
                                </button>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {            
            model_permissions: [],
            permissions: [],
            temp: [],
            title: [
                {
                    name: 'Dashboard',
                    permission: 'dashboard'
                },
                {
                    name: 'User',
                    permission: 'user'
                },
                {
                    name: 'Admin',
                    permission: 'admin'
                },
                {
                    name: 'Doctor',
                    permission: 'doctor'
                },
                {
                    name: 'Emoticon',
                    permission: 'emoticon'
                },
                {
                    name: 'Wise Sentence',
                    permission: 'sentence'
                },
                {
                    name: 'Music',
                    permission: 'music'
                },
                {
                    name: 'Place Category',
                    permission: 'category'
                },
                {
                    name: 'Place',
                    permission: 'place'
                },
                {
                    name: 'Role & Permission',
                    permission: 'role'
                }
            ],
            isLoading: false
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.post('/role/role-permission', { id: _.$route.params.id })
                .then(resp=>{
                    for (let idx = 0; idx < resp.data.data.length; idx++) {
                        _.model_permissions.push(resp.data.data[idx].name);
                    }
                    for (let idx = 0; idx < _.permissions.length; idx++) {
                        if(_.model_permissions.indexOf(_.permissions[idx].name) != -1){
                            _.temp.push('1')
                        } else {
                            _.temp.push('0')
                        }
                    }
                    _.model_permissions = _.temp
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_permissions() {
            let _ = this
            _.axios.get('/role/permissions', _.model)
                .then(resp=>{
                    _.permissions = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            let data = []
            for (let idx = 0; idx < _.model_permissions.length; idx++) {
                if(_.model_permissions[idx] == "1"){
                    data.push(_.permissions[idx].name)
                }
            }

            let formdata = {
                id: _.$route.params.id,
                permissions: data
            }
            
            _.isLoading = true
            _.axios.post('/role/set-role-permission', formdata)
                .then(resp => {
                    _.$router.push('/role/' + _.$route.params.id)
                    _.$toast.success(resp.data.message)
                    _.isLoading = false
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                    _.isLoading = false
                })
        },
        generateName(x){
            let str = x.split('/')
            return str[1] + ' ' + str[0];
        }
    },
    async mounted() {
        let _ = this
        await _.get_permissions()
        await _.get()
    }
}
</script>