<template>	
    <aside>
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="fa fa-users mr-1"></i> 
                                    Users / List
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">Detail</h2>
            </div>
        </div>
        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist" style="border: 0px">
                                        <li class="nav-item" v-can="'user/info'">
                                            <a class="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true" @click="changeTab('info')">Info</a>
                                        </li>
                                        <li class="nav-item" v-can="'user/transaction'">
                                            <a class="nav-link" id="transaction-tab" data-toggle="tab" href="#transaction" role="tab" aria-controls="transaction" aria-selected="false" @click="changeTab('transaction')">Transaction History</a>
                                        </li>
                                        <li class="nav-item" v-can="'user/log'">
                                            <a class="nav-link" id="log-tab" data-toggle="tab" href="#log" role="tab" aria-controls="log" aria-selected="false" @click="changeTab('log')">Log</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                                            <div class="d-flex flex-wrap mt-2">                                        
                                                <div class="col-md-12 col-lg-2">
                                                    <div style="text-align: -webkit-center;">
                                                        <div class="profile-avatar">
                                                            <img :src="temp_photo ? temp_photo:'/assets/img/icon/profile.png'" class="img-fluid" alt="">
                                                        </div>                                                  
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <p class="mb-0">Full Name</p>
                                                    <p class="f-16"><b>{{model.name}}</b></p>

                                                    <p class="mb-0">Email</p>
                                                    <p class="f-16"><b>{{model.email}}</b></p>

                                                    <p class="mb-0">Call</p>
                                                    <p class="f-16"><b>{{model.col_call}}</b></p>

                                                    <p class="mb-0">Birth Date</p>
                                                    <p class="f-16"><b>{{model.birth_date | moment('LL')}}</b></p>

                                                    <p class="mb-0">Phone Number</p>
                                                    <p class="f-16"><b>{{model.phone_number}}</b></p>

                                                    <p class="mb-0">Registered Date</p>
                                                    <p class="f-16"><b>{{model.created_at | moment('LL HH.m')}} WIB</b></p>

                                                    <p class="mb-0">Number of Friends</p>
                                                    <p class="f-16"><b>{{model.total_friend}}</b></p>

                                                    <p class="mb-0">Status</p>
                                                    <div class="row">
                                                        <div class="col">
                                                            <img :src="data.image.url" class="avatar-small" v-for="(data, index) in model.emoticons" :key="index">
                                                        </div>
                                                    </div>
                                                    <p class="f-16"><b>{{model.status}}</b></p>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="tab-pane fade" id="transaction" role="tabpanel" aria-labelledby="transaction-tab">
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col mb-1">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-12 input-group form-search-rounded">
                                                            <input class="form-control border-right-0 border" v-model.lazy="transaction_search" type="search" id="example-search-input" placeholder="Search Transaction">
                                                            <span class="input-group-append">
                                                                <div class="input-group-text bg-transparent">
                                                                    <i class="fa fa-search"></i>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-12 mb-1">
                                                    <div class="row float-right">
                                                        <div class="align-self-center text-right mr-1">
                                                            Show
                                                        </div>
                                                        <div class=" mr-1">
                                                            <select v-model="transaction_limit" class="form-control">                        
                                                                <option value="10">10</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                                <option value="250">250</option>
                                                                <option value="500">500</option>
                                                                <option value="1000">1000</option>
                                                                <option value="0">All</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col mb-1">
                                                    <div class="btn-responsive">
                                                        <button type="button" @click="transaction_filter = ''" :class="transaction_filter === '' ? 'btn-success' : 'btn-muted-trans'" class="btn waves-effect waves-light">
                                                            All
                                                        </button>
                                                        <button type="button" @click="transaction_filter = 'consultation'" :class="transaction_filter === 'consultation' ? 'btn-success' : 'btn-muted-trans'" class="btn waves-effect waves-light ml-1">
                                                            Consultation
                                                        </button>
                                                        <button type="button" @click="transaction_filter = 'music'" :class="transaction_filter === 'music' ? 'btn-success' : 'btn-muted-trans'" class="btn waves-effect waves-light ml-1">
                                                            Music
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <th width=5%>No</th>
                                                        <th>ID</th>
                                                        <th>Date</th>
                                                        <th>Total</th>
                                                        <th>Type</th>
                                                        <th>Payment Method</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody v-if="transaction_table.length > 0">
                                                        <tr v-for="(data, index) in transaction_table" :key="index">
                                                            <td>{{index+1}}</td>
                                                            <td>{{data.updated_at | moment("DD/MM/YYYY")}}</td>
                                                            <td>
                                                                <div class="btn-action-block">
                                                                    <router-link :to="'transaction/' + data.id">
                                                                        <button type="button" class="btn btn-info waves-effect waves-light btn-rounded">
                                                                            <i class="fa fa-eye"></i>
                                                                        </button>
                                                                    </router-link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td colspan="8">Data not found.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="log" role="tabpanel" aria-labelledby="log-tab">
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="row">
                                                        <div class="col-lg-5 col-md-12 mb-1">
                                                            <div class="position-relative has-icon-right">
                                                                <date-picker
                                                                    v-model="log_date_start"
                                                                    format="DD/MM/YYYY"
                                                                    type="date"
                                                                    input-class='form-control'
                                                                    value-type="YYYY-MM-DD"
                                                                    placeholder="Start Date"
                                                                    style="width: 100%;">
                                                                </date-picker>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-5 col-md-12 mb-1">
                                                            <div class="position-relative has-icon-right">
                                                                <date-picker
                                                                    v-model="log_date_end"
                                                                    format="DD/MM/YYYY"
                                                                    type="date"
                                                                    input-class='form-control'
                                                                    value-type="YYYY-MM-DD"
                                                                    placeholder="End Date"
                                                                    style="width: 100%;">
                                                                </date-picker>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-2 col-md-12 mb-1">
                                                            <button type="button" @click="get_log()" class="btn-success btn waves-effect waves-light">
                                                                Filter
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-12 mb-1">
                                                    <div class="row float-right">
                                                        <div class="align-self-center text-right mr-1">
                                                            Show
                                                        </div>
                                                        <div class=" mr-1">
                                                            <select v-model="log_limit" class="form-control">
                                                                <option value="10">10</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                                <option value="250">250</option>
                                                                <option value="500">500</option>
                                                                <option value="1000">1000</option>
                                                                <option value="0">All</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <th width=5%>No</th>
                                                        <th>Date</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody v-if="log_table.data.length > 0">
                                                        <tr v-for="(data, index) in log_table.data" :key="index">
                                                            <td>{{index+1}}</td>
                                                            <td>{{data.created_at | moment("DD/MM/YYYY HH.m")}} WIB</td>
                                                            <td>{{data.col_description}}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td colspan="3">Data not found.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-5 mt-2">
                                                    Showing <b>{{log_table.from}} - {{log_table.to}}</b> from <b>{{log_table.total}} Data</b>
                                                </div>
                                                <div class="col-sm-12 col-md-7 mt-1">
                                                    <div class="dataTables_paginate paging_simple_numbers pull-right">
                                                        <ul class="pagination">
                                                            <li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="isCurrentPage(page) ? 'active' : ''">
                                                                <button type="button" class="page-link" @click.prevent="changePage(page)">{{ page }}</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>        
    </aside>	
</template>

<script>
export default {
	data() {
		return {
            tab: 'info',
            model: {},
            temp_photo: null,

            transaction_table: [],
            transaction_search: '',
            transaction_filter: '',
            transaction_limit: 10,

            log_table: { data: [] },
            log_limit: 10,
            log_date_start: '',
            log_date_end: ''
		}
    },
    mounted() {
        let _ = this
        _.get()
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/account/user?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    if (_.model.photo != null){
                        _.temp_photo = _.model.photo.url
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_log(page) {
            let _ = this
            _.axios.get('/account/user/log?id_account=' + _.$route.params.id 
                    + '&date_start=' + _.log_date_start
                    + '&date_end=' + _.log_date_end
                    + '&limit='+ _.log_limit 
                    + '&page=' + page)
                .then(resp=>{
                    _.log_table = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        changeTab(tab){
            let _ = this
            _.tab = tab
            if (_.tab == 'info'){
                // _.get()
            } else if (_.tab == 'transaction') {
                // _.get_transaction()
            } else {
                _.log_table = { data: [] }
                _.log_limit = 10
                _.log_date_start = ''
                _.log_date_end = ''
                _.get_log()
            }
        },
        isCurrentPage(page){
            let _ = this
            if (_.tab == 'log'){
                return _.log_table.current_page === page
            } else {
                return _.transaction_table.current_page === page
            }
		},
		changePage(page) {
            let _ = this
            if (_.tab == 'log'){
                if (page > _.log_table.last_page) {
                    page = _.log_table.last_page;
                }
                _.log_table.current_page = page;
                _.get_log(page);
            } else {
                if (page > _.transaction_table.last_page) {
                    page = _.transaction_table.last_page;
                }
                _.transaction_table.current_page = page;
                // _.get_transaction(page);
            }
			window.scrollTo(0,0);
		}
    },
    computed: {
		pages() {
            let _ = this
            let current = 0
            let last = 0    

            if (_.tab == 'log'){
                current         = _.log_table.current_page
			    last            = _.log_table.last_page
            } else {
                current         = _.transaction_table.current_page
			    last            = _.transaction_table.last_page
            }

			let delta           = 2
			let left            = current - delta
			let right           = current + delta + 1
			let range           = []
			let rangeWithDots   = []  
			let l               = 0              

			for (let i = 1; i <= last; i++) {
				if (i == 1 || i == last || i >= left && i < right) {
					range.push(i);
				}
			}

			for (let i of range) {
				if (l) {
					if (i - l === 2) {
						rangeWithDots.push(l + 1);
					} else if (i - l !== 1) {
						rangeWithDots.push('...');
					}
				}
				rangeWithDots.push(i);
				l = i;
			}

			return rangeWithDots;
		}
	},
    watch: {
        limit_log: function(){
            this.get_log()
        },
        transaction_limit: function(){
            // this.get_transaction()
        }
    }
}
</script>