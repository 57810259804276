<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :icon="table.icon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-6 input-group form-search-rounded">
                        <input class="form-control border-right-0 border" v-model="search" type="search" placeholder="Search Music">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search"></i>
                            </div>
                        </span>
                    </div>
                </div>                
			</template>

            <template v-slot:button>
				<div>
                    <router-link to="/music/add" v-can="'music/create'">
                        <button type="button" class="btn btn-primary">
                            <i class="fa fa-plus"></i> Add Music
                        </button>
                    </router-link>
				</div>
			</template> 

            <template v-slot:limit>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Show
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">All</option>
                        </select>
                    </div>
				</div>
			</template>	

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th>Category</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Price</th>
                        <th width="10%">Action</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <router-link :to="'/music/' + data.id" v-can="'music/update'">
                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded" title="Edit">
                            <i class="fa fa-edit"></i>
                        </button>
                    </router-link>
                    <button type="button" @click="destroy(data)" v-can="'music/delete'" class="btn btn-danger waves-effect waves-light btn-rounded" title="Delete">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'UserIndex',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List",
				breadcumb: "Musics",
                icon: "fa-music",
                dataTable: {
                    lists: [],
                    columns: ['col_category', 'name', 'col_price_type', 'price']
                }
			},
            search: '',
            limit: 10,
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/music?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Delete Music?',                
                html:   '<p class="popup-title">'+ x.name + '</p>' +
                        '<p class="popup-text-normal">Music that have been deleted cannot be returned.</p>',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Delete',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/music/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>