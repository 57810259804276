import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'

import UserIndex from '../views/user/Index.vue'
import UserDetail from '../views/user/Detail.vue'
import EditProfile from '../views/user/EditProfile.vue'
import ChangePassword from '../views/user/ChangePassword.vue'

import EmoticonIndex from '../views/emoticon/Index.vue'
import EmoticonForm from '../views/emoticon/Form.vue'

import WiseSentenceIndex from '../views/wise_sentence/Index.vue'
import WiseSentenceForm from '../views/wise_sentence/Form.vue'

import MusicIndex from '../views/music/Index.vue'
import MusicForm from '../views/music/Form.vue'

import PlaceCategoryIndex from '../views/place/category/Index.vue'
import PlaceCategoryForm from '../views/place/category/Form.vue'
import PlaceIndex from '../views/place/Index.vue'
import PlaceForm from '../views/place/Form.vue'

import AdminIndex from '../views/admin/Index.vue'
import AdminForm from '../views/admin/Form.vue'

import RoleIndex from '../views/role/Index.vue'
import RoleForm from '../views/role/Form.vue'
import RolePermissions from '../views/role/FormPermissions.vue'

import DoctorIndex from '../views/doctor/Index.vue'
import DoctorDetail from '../views/doctor/Detail.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home,
                meta: {
                    permissions: ['dashboard/read']
                }
            },
            {
                path: '/user/edit_profile',
                name: 'EditProfile',
                component: EditProfile
            },
            {
                path: '/user/change_password',
                name: 'ChangePassword',
                component: ChangePassword
            },
            {
                path: '/user',
                name: 'UserIndex',
                component: UserIndex,
                meta: {
                    permissions: ['user/read']
                }
            },
            {
                path: '/user/:id',
                name: 'UserDetail',
                component: UserDetail,
                meta: {
                    permissions: ['user/read']
                }
            },
            {
                path: '/emoticon',
                name: 'EmoticonIndex',
                component: EmoticonIndex,
                meta: {
                    permissions: ['emoticon/read']
                }
            },
            {
                path: '/emoticon/add',
                name: 'EmoticonFormAdd',
                component: EmoticonForm,
                meta: {
                    permissions: ['emoticon/create']
                }
            },
            {
                path: '/emoticon/:id',
                name: 'EmoticonFormEdit',
                component: EmoticonForm,
                meta: {
                    permissions: ['emoticon/update']
                }
            },
            {
                path: '/wise',
                name: 'WiseSentenceIndex',
                component: WiseSentenceIndex,
                meta: {
                    permissions: ['sentence/read']
                }
            },
            {
                path: '/wise/add',
                name: 'WiseSentenceFormAdd',
                component: WiseSentenceForm,
                meta: {
                    permissions: ['sentence/create']
                }
            },
            {
                path: '/wise/:id',
                name: 'WiseSentenceFormEdit',
                component: WiseSentenceForm,
                meta: {
                    permissions: ['sentence/update']
                }
            },
            {
                path: '/music',
                name: 'MusicIndex',
                component: MusicIndex,
                meta: {
                    permissions: ['music/read']
                }
            },
            {
                path: '/music/add',
                name: 'MusicFormAdd',
                component: MusicForm,
                meta: {
                    permissions: ['music/create']
                }
            },
            {
                path: '/music/:id',
                name: 'MusicFormEdit',
                component: MusicForm,
                meta: {
                    permissions: ['music/update']
                }
            },
            {
                path: '/place',
                name: 'PlaceIndex',
                component: PlaceIndex,
                meta: {
                    permissions: ['place/read']
                }
            },
            {
                path: '/place/add',
                name: 'PlaceFormAdd',
                component: PlaceForm,
                meta: {
                    permissions: ['place/create']
                }
            },
            {
                path: '/place/edit/:id',
                name: 'PlaceFormEdit',
                component: PlaceForm,
                meta: {
                    permissions: ['place/update']
                }
            },
            {
                path: '/place/category',
                name: 'PlaceCategoryIndex',
                component: PlaceCategoryIndex,
                meta: {
                    permissions: ['category/read']
                }
            },
            {
                path: '/place/category/add',
                name: 'PlaceCategoryFormAdd',
                component: PlaceCategoryForm,
                meta: {
                    permissions: ['category/create']
                }
            },
            {
                path: '/place/category/:id',
                name: 'PlaceCategoryFormEdit',
                component: PlaceCategoryForm,
                meta: {
                    permissions: ['category/update']
                }
            },
            {
                path: '/admin',
                name: 'AdminIndex',
                component: AdminIndex,
                meta: {
                    permissions: ['admin/read']
                }
            },
            {
                path: '/admin/add',
                name: 'AdminFormAdd',
                component: AdminForm,
                meta: {
                    permissions: ['admin/create']
                }
            },
            {
                path: '/admin/:id',
                name: 'AdminFormEdit',
                component: AdminForm,
                meta: {
                    permissions: ['admin/update']
                }
            },
            {
                path: '/role',
                name: 'RoleIndex',
                component: RoleIndex,
                meta: {
                    permissions: ['role/read']
                }
            },
            {
                path: '/role/add',
                name: 'RoleFormAdd',
                component: RoleForm,
                meta: {
                    permissions: ['role/create']
                }
            },
            {
                path: '/role/:id',
                name: 'RoleFormEdit',
                component: RoleForm,
                meta: {
                    permissions: ['role/update']
                }
            },
            {
                path: '/role/:id/permissions',
                name: 'RolePermissions',
                component: RolePermissions,
                meta: {
                    permissions: ['role/permission']
                }
            },
            {
                path: '/doctor',
                name: 'DoctorIndex',
                component: DoctorIndex,
                meta: {
                    permissions: ['doctor/read']
                }
            },
            {
                path: '/doctor/detail/:id',
                name: 'DoctorDetail',
                component: DoctorDetail,
                meta: {
                    permissions: ['doctor/read']
                }
            },
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
                meta: {
                    isGuest: true
                }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if ( to.meta.isGuest ) {
        next()
    } else if ( !store.state.token && !localStorage.getItem('token') ) {
        next({name: 'Login'})
    } else if (to.meta.permissions){
        let permissions = to.meta.permissions.toString().split(',')
        let isAllowed = false
        permissions.forEach(elm => {				
            if (localStorage.getItem('permissions').split(',').indexOf(elm) != -1 ) {					
                isAllowed = true
            }
        });
        if (isAllowed){
            next()
        } else{
            Vue.$toast.warning("Sorry, you don't have access")
            let searchPermission = localStorage.getItem('permissions').split(',').filter(x => x.includes('/read'));
            if (searchPermission.length > 0) {
                switch (searchPermission[0]) {
                    case 'dashboard/read':
                        next({name: 'Home'})
                        break;
                    case 'user/read':
                        next({name: 'UserIndex'})
                        break;
                    case 'admin/read':
                        next({name: 'AdminIndex'})
                        break;
                    case 'doctor/read':
                        next({name: 'DoctorIndex'})
                        break;
                    case 'emoticon/read':
                        next({name: 'EmoticonIndex'})
                        break;
                    case 'sentence/read':
                        next({name: 'WiseSentenceIndex'})
                        break;
                    case 'music/read':
                        next({name: 'MusicIndex'})
                        break;
                    case 'category/read':
                        next({name: 'PlaceCategoryIndex'})
                        break;
                    case 'place/read':
                        next({name: 'PlaceIndex'})
                        break;
                    case 'role/read':
                        next({name: 'RoleIndex'})
                        break;
                
                    default:
                        next({name: 'EditProfile'})
                        break;
                }
            } else {
                next({name: 'EditProfile'})
            }
        }
    } else {
        next()
    }
})

export default router
