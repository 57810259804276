<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="fa fa-cogs mr-1"></i>
                                    Roles & Permissions / {{ $route.params.id ? 'Edit' : 'Add'}} Role
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/role">
                                        <i class="feather icon-arrow-left"></i> 
                                        Back
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{ $route.params.id ? 'Edit' : 'Add'}} Role</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col">
                        <ValidationObserver class="card-content" ref="observer">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <ValidationProvider name="Role name" v-slot="{ errors }" rules="required">
                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Role Name</label>
                                                    <input type="text" v-model="model.name" id="name" class="form-control"
                                                        placeholder="Input role name">
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                            <ValidationProvider name="Status" v-slot="{ errors }" rules="required">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Status</label>
                                                    <select class="form-control col-6" v-model="model.status">
                                                        <option value="">Choose Status</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">Not Active</option>
                                                    </select>
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>
                                            <router-link v-can="'role/permission'" v-if="$route.params.id" :to="'/role/' + $route.params.id + '/permissions'">
                                                <button type="button" class="btn btn-warning">
                                                        Configure Role's Permissions
                                                </button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="isLoading">
                                    <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Save</div>
                                </button>
                            </form>
                        </ValidationObserver>
                        <button class="btn btn-danger float-right mr-1" v-if="$route.params.id" v-can="'role/delete'" @click="destroy()">
                            Delete
                        </button>
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {            
            model: {
                status: ''
            },
            isLoading: false
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/role?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                if (!_.$route.params.id){
                    _.isLoading = true
                    _.axios.post('/role', _.model)
                        .then(resp => {
                            _.$router.push('/role/' + resp.data.data.id)
                            _.$toast.success(resp.data.message)
                            _.isLoading = false
                        })
                        .catch(err => {
                            _.$toast.warning(err.response.data.message)
                            _.isLoading = false
                        })
                } else {
                    _.isLoading = true
                    _.axios.patch('/role', _.model)
                        .then(resp => {
                            _.get()
                            _.$toast.success(resp.data.message)
                            _.isLoading = false
                        })
                        .catch(err => {
                            _.$toast.warning(err.response.data.message)
                            _.isLoading = false
                        })
                }
            }
        },
        destroy() {
            let _ = this
            _.$swal.fire({
                title: 'Delete Role?',                
                html:   '<p class="popup-title">'+ _.model.name + '</p>' +
                        '<p class="popup-text-normal">Role that have been deleted cannot be returned.</p>',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Delete',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/role', { data: { id: _.$route.params.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.$router.push('/role')
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                _.$toast.error(err.response.data.message)
                            })                        
                    }
            })
        },
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
    }
}
</script>